// import Swiper, {Autoplay, Pagination, EffectFade, Navigation} from 'swiper';
// import 'swiper/swiper.scss';
// require('perfect-scrollbar/css/perfect-scrollbar.css');
// import '../sass/style.scss'
import $ from 'jquery'
import 'jquery.easing'
import 'svgxuse'
import Cookies from 'js-cookie';
import { lockBG, modalWindow, menu } from './utils/functions.js';


function requireAll(r){
  r.keys().forEach(r);
}

requireAll(require.context('../img/', true, /\.svg$/));

//Swiper.use([Navigation, Pagination, Autoplay]);

const $win = $(window);
let winHeight = $win.height();

$('body').removeClass('no-animation');

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);


const getUA = function(){
  
  let _ua = navigator.userAgent.toLowerCase();
  let _o = {
    ltIE6:typeof window.addEventListener == "undefined" && typeof document.documentElement.style.maxHeight == "undefined",
    ltIE7:typeof window.addEventListener == "undefined" && typeof document.querySelectorAll == "undefined",
    ltIE8:typeof window.addEventListener == "undefined" && typeof document.getElementsByClassName == "undefined",
    IE9: navigator.appVersion.toLowerCase().indexOf("msie 9.") != -1,
    IE10 : navigator.userAgent.match(/Trident\/[6]/i),
    IE11: navigator.userAgent.match(/Trident\/7\./),
    IE:document.uniqueID,
    Firefox:window.sidebar,
    Opera:window.opera,
    chrome: _ua.indexOf('chrome') > -1,
		safari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    //Webkit:!document.uniqueID && !window.opera && !window.sidebar && !window.orientation && window.localStorage,
    mobile:/android|iphone|ipad|ipod/i.test(navigator.userAgent.toLowerCase()),
    iphone:/iphone|ipod/i.test(_ua),
    android:/android/.test(_ua),
    ipad:/ipad/.test(_ua),
    tablet:undefined,
    smartphone:undefined,
    touch: window.ontouchstart === null
  }
  _o.tablet = _o.ipad;
  if(!_o.tablet && _o.android){ _o.tablet = !(/mobile/.test(_ua));}				
  _o.smartphone = _o.iphone || _o.android ? true : false;
  
  let v = [];
  if (/iP(hone|od|ad)/.test(navigator.platform)) {v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);}
  let vAry = [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  _o.iosV = vAry[0] ? vAry[0] : '';

  return _o;
  
}

window.matchMedia || (window.matchMedia = function() {
  "use strict";

  // For browsers that support matchMedium api such as IE 9 and webkit
  var styleMedia = (window.styleMedia || window.media);

  // For those that don't support matchMedium
  if (!styleMedia) {
      var style       = document.createElement('style'),
          script      = document.getElementsByTagName('script')[0],
          info        = null;

      style.type  = 'text/css';
      style.id    = 'matchmediajs-test';

      if (!script) {
        document.head.appendChild(style);
      } else {
        script.parentNode.insertBefore(style, script);
      }

      // 'style.currentStyle' is used by IE <= 8 and 'window.getComputedStyle' for all other browsers
      info = ('getComputedStyle' in window) && window.getComputedStyle(style, null) || style.currentStyle;

      styleMedia = {
          matchMedium: function(media) {
              var text = '@media ' + media + '{ #matchmediajs-test { width: 1px; } }';

              // 'style.styleSheet' is used by IE <= 8 and 'style.textContent' for all other browsers
              if (style.styleSheet) {
                  style.styleSheet.cssText = text;
              } else {
                  style.textContent = text;
              }

              // Test if media query is true or false
              return info.width === '1px';
          }
      };
  }

  return function(media) {
      return {
          matches: styleMedia.matchMedium(media || 'all'),
          media: media || 'all'
      };
  };
}());

const resizeHandler = (e)=>{
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  winHeight = $win.height();

  setTimeout(()=>{
    let mt = window.orientation == 0 ? window.innerHeight - 280 : window.innerWidth;
    $('.result-content').css({marginTop: mt});
  },200)

  if(window.orientation == 0){
    $('body').removeClass('win--h');
  } else {
    $('body').addClass('win--h');
  }

}

const pageScrollTo = (tar, duration, e) => {

  tar = tar.replace(window.theme.site_url, '');
  tar = tar.replace('#', '');

  if($('[data-id="' + tar + '"]').length || tar == 'header'){
    if(e)
    e.preventDefault();

    let diff = 0;
    let pos = tar == 'header' ? 0 : $('[data-id="'+ tar +'"]').offset().top - diff;

    let speed = duration ? duration : 500;

    $('html,body').stop().animate({ scrollTop: pos }, speed, 'easeOutExpo');

    if($('.mobile-modal-site-nav, .modal-site-nav').hasClass('visible')){
      $('.mobile-modal-site-nav, .modal-site-nav').removeClass('visible')
    }

  }

}

const mobNav = new menu();

$win.on('orientationchange', resizeHandler)
resizeHandler();

$('.anchor, a').on('click', (e)=>{
  let href = $(e.currentTarget).attr('href');
  href = href.replace(window.theme.site_url, '').replace(window.theme.current_path, '');
  let tgt = $(e.currentTarget).attr('href').split('#');

  if(tgt[1] && href == '#' + tgt[1]){
    if($('[data-id="'+tgt[1]+'"]').length)
    pageScrollTo(tgt[1], 500);
  }
});

if(location.hash){
  let hash = location.hash;
  pageScrollTo(hash);
}

$.event.add(window, "load", ()=>{
});



const cookieKey = 'notificationbar'
const cookieValue = Cookies.get(cookieKey);
if(!cookieValue){

  $('.notification-bar').addClass('visible');

  $('.notification-bar__close-button').on('click', ()=>{
    $('.notification-bar').removeClass('visible');
    $('.notification-bar').addClass('hidden');
    setTimeout(()=>{
      $('.notification-bar').remove();
      Cookies.set(cookieKey, 'true', { expires: 7 });
    }, 700);
  });

} else {

  $('.notification-bar').remove();

}